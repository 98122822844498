export const STATUS_RECEIVE = Object.freeze({
	ACTIVE: "Em Aberto",
	PAID: "Baixado",
	CANCELED: "Cancelado"
});

export const MONTHS = Object.freeze({
	JANEIRO: "1",
	FEVEREIRO: "2",
	MARCO: "3",
	ABRIL: "4",
	MAIO: "5",
	JUNHO: "6",
	JULHO: "7",
	AGOSTO: "8",
	SETEMBRO: "9",
	OUTUBRO: "10",
	NOVEMBRO: "11",
	DEZEMBRO: "12"
});
